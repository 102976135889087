var render = function () {
  var _vm = this
  var _h = _vm.$createElement
  var _c = _vm._self._c || _h
  return _c(
    "div",
    [
      _c(
        "Modal",
        {
          attrs: {
            visible: _vm.transferModal,
            closable: true,
            size: "normal",
            title: "转交审批",
            "mask-closable": false,
            "ok-text": "确认",
          },
          on: { ok: _vm.handleSubmit, cancel: _vm.handleCancel },
        },
        [
          _vm.approverList.length > 0
            ? _c("div", { staticClass: "approver-box" }, [
                _c("p", { staticClass: "transfer-p" }, [
                  _vm._v("需要转交的审批人"),
                ]),
                _c(
                  "div",
                  { staticClass: "approver" },
                  _vm._l(_vm.approverList, function (item, index) {
                    return _c(
                      "div",
                      {
                        key: item.approverId,
                        on: {
                          click: function ($event) {
                            return _vm.changeUser(index)
                          },
                        },
                      },
                      [
                        _c(
                          "div",
                          { staticClass: "v-name-circl" },
                          [
                            _c("Avater", {
                              attrs: {
                                "on-job-id": "",
                                "avater-name": item.approverName,
                                size: "60",
                                "show-user-name": false,
                              },
                            }),
                          ],
                          1
                        ),
                        _c(
                          "div",
                          { class: _vm.active === index ? "active" : "" },
                          [_vm._v(_vm._s(item.approverName))]
                        ),
                      ]
                    )
                  }),
                  0
                ),
              ])
            : _vm._e(),
          _c(
            "div",
            { staticClass: "operation" },
            [
              _c("p", { staticClass: "transfer-p" }, [_vm._v("转交对象")]),
              _c("PerTreeSelect", {
                attrs: {
                  "per-tree-value": _vm.form.targetUserId,
                  placeholder: "请选择人员",
                },
                on: { getPerTreeValue: _vm.getPerTreeValue },
              }),
            ],
            1
          ),
        ]
      ),
    ],
    1
  )
}
var staticRenderFns = []
render._withStripped = true

export { render, staticRenderFns }